<template>
  <Dialog
    :header="exibirHeader()"
    :visible="visible"
    :update="update"
    :closeOnEscape="!saving"
    :closable="!saving"
    :contentStyle="{ overflow: 'visible' }"
    :modal="true"
    @update:visible="fecharDialog()"
    @show="onShow()"
    @hide="onHide()">
    <BlockUI :blocked="saving">
      <Card>
        <template #content>
          <div class="p-fluid formgrid grid">
            <div class="field col md:col">
              <label for="nome" class="required">Nome</label>
              <InputText id="nome" v-model="role.nome" type="text" suffix="%" />
              <div
                v-if="submitted && v$.role.nome.required.$invalid"
                class="p-error">
                O campo Nome é obrigatório.
              </div>
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fecharDialog()" />
      <Button :disabled="saving" autofocus @click="validate">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>
  </Dialog>
</template>
<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Role from '@/domain/Role.js'
import RoleService from '@/service/RoleService'

export default {
  props: {
    visible: { type: Boolean, required: true },
    update: { type: Boolean, required: true },
    propsrole: {
      type: Object,
      required: false,
      default: new Role(),
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      role: new Role(),
      saving: false,
      submitted: false,
    }
  },

  validations() {
    return {
      role: {
        nome: { required },
      },
    }
  },

  created() {
    this.roleService = new RoleService(this.$http)
  },

  methods: {
    exibirHeader() {
      return this.update ? 'Editar Role' : 'Nova Role'
    },

    fecharDialog() {
      this.role = {}
      this.$emit('update:visible', false)
      this.$emit('update:update', false)
    },

    validate() {
      this.submitted = true
      this.v$.role.$touch()

      return this.v$.role.$invalid ? '' : this.salvar()
    },

    salvar() {
      this.saving = true
      this.roleService
        .salvarRole(this.role)
        .then(() => {
          this.exibirToastSuccess('Role salva com sucesso!')
          this.fecharDialog()
        })
        .catch((err) => {
          this.exibirToastError(err)
        })
        .finally(() => {
          this.saving = false
          this.$emit('carregarDados')
          this.fecharDialog()
        })
    },

    onShow() {
      this.saving = false
      this.role = JSON.parse(JSON.stringify(this.propsrole))
    },

    onHide() {},

    exibirToastSuccess(message) {
      this.$toast.add({
        severity: 'success',
        summary: message,
        life: 10000,
      })
    },

    exibirToastError(err) {
      this.$toast.add({
        severity: 'error',
        summary: err.response.data.message,
        life: 10000,
      })
    },
  },
}
</script>
<style></style>
